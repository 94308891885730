
  import { Component, Vue } from "vue-property-decorator";
  import { formatDate, formatTime, getTimeZone } from "@/date-utils";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { IEnrollmentAttendee, IEventAdmin } from "@/interfaces";
  import { dispatchCheckApiError } from "@/store/main/actions";
  import { dispatchGetAnswersReportByEvent } from "@/store/admin/actions";
  import { commitAddNotification } from "@/store/main/mutations";

  @Component
  export default class AdminEvents extends Vue {
    public headers = [
      {
        text: "Email",
        sortable: true,
        value: "user.email",
        align: "left",
      },
      {
        text: "First Name",
        sortable: true,
        value: "user.first_name",
        align: "left",
      },
      {
        text: "Last Name",
        sortable: true,
        filterable: true,
        value: "user.last_name",
        align: "left",
      },
      {
        text: "Test Completed Date",
        sortable: true,
        value: "test_completed_date",
        align: "left",
      },
      {
        text: "Materials Download Available",
        sortable: true,
        value: "has_download_available",
        align: "center",
      },
      {
        text: `Materials Downloaded At (${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        })`,
        sortable: true,
        value: "last_downloaded_materials_on",
        align: "left",
      },
      {
        text: "Actions",
        value: "actions",
        width: "100px",
      },
    ];

    loading = false;
    event: IEventAdmin | null = null;
    enrollments: IEnrollmentAttendee[] = [];
    dialog = false;
    postTestCode: string | null = null;
    deleteDialog = false;
    enrollmentToDelete: IEnrollmentAttendee | null = null;
    downloadingReport = false;
    snackbar = false;

    async refreshData() {
      const token = readToken(this.$store);
      this.loading = true;
      const eventId = +this.$route.params.id;
      api.getEventAdmin(token, eventId).then((resp) => {
        this.event = resp.data;
      });
      try {
        const resp = await api.getEventAttendees(token, eventId);
        this.enrollments = resp.data;
      } catch {
        this.enrollments = [];
      } finally {
        this.loading = false;
      }
    }

    async mounted() {
      await this.refreshData();
    }

    formatDate(date: string, utc_offset: string | null) {
      return formatDate(date, utc_offset);
    }

    formatTime(date: string, utc_offset: string, time_zone: string) {
      return `${formatTime(date, utc_offset)} ${getTimeZone(utc_offset, time_zone)}`;
    }

    formatLastDownloadedAt(date: string | null) {
      if (date) {
        return `${formatDate(date, null)}, ${formatTime(date, null)}`;
      } else {
        return "";
      }
    }

    async giveDownload(enrollmentId: number) {
      this.loading = true;
      const token = readToken(this.$store);
      const resp = await api.giveDownload(token, enrollmentId);
      const idx = this.enrollments.map((e) => e.id).indexOf(enrollmentId);
      if (idx > -1) {
        this.enrollments[idx] = resp.data;
        this.enrollments = [...this.enrollments];
      }
      this.loading = false;
    }

    async updateEvent() {
      this.dialog = false;
      if (this.postTestCode && this.event) {
        const token = readToken(this.$store);
        const resp = await api.updateEvent(token, this.event.id, {
          post_test_code: this.postTestCode,
        });
        this.event = resp.data;
      }
    }

    showDeleteDialog(enrollmentId: number) {
      this.enrollmentToDelete =
        this.enrollments.find((enrollment) => enrollment.id === enrollmentId) || null;
      this.deleteDialog = true;
    }

    async removeEnrollment() {
      if (!this.enrollmentToDelete) {
        return;
      }

      const token = readToken(this.$store);
      try {
        const resp = await api.removeEnrollment(token, this.enrollmentToDelete.id);
        const idx = this.enrollments.map((e) => e.id).indexOf(resp.data.id);
        this.enrollments.splice(idx, 1);
        this.deleteDialog = false;
        commitAddNotification(this.$store, {
          content: `Enrollment Deleted for user ${this.enrollmentToDelete.user.first_name} ${this.enrollmentToDelete.user.last_name}`,
          color: "success",
        });
      } catch (error) {
        this.deleteDialog = false;
        dispatchCheckApiError(this.$store, error);
      }
    }

    backPressed() {
      this.$router.back();
    }

    async downloadReport() {
      this.downloadingReport = true;
      const resp = await dispatchGetAnswersReportByEvent(this.$store, {
        eventId: +this.$route.params.id,
      });

      if (resp) {
        var fileName = "Answer_Report";
        if (resp.headers["content-disposition"]?.includes("filename=")) {
          fileName = resp.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "");
        }
        this.downloadFile(resp.data, fileName);
      } else {
        this.snackbar = true;
      }
      this.downloadingReport = false;
    }

    downloadFile(file: Blob, fileName: string) {
      var fileURL = window.URL.createObjectURL(file);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    }
  }
